<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="8" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                        <ValidationProvider name="academic_records" vid="academic_records">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="academic_records"
                                                >
                                                <template v-slot:label>
                                                {{ $t('barc_config.item_id') }}
                                                </template>
                                               <p class="text-dark"> {{ $n(scoreSetupData.item_id) }} </p>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Service Length" vid="service_length" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="service_length"
                                                >
                                                <template v-slot:label>
                                                {{ $t('barc_config.item_name_en') }}
                                                </template>
                                                <p class="text-dark"> {{ scoreSetupData.item_name }} </p>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Age" vid="age">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="age"
                                                >
                                                <template v-slot:label>
                                                {{ $t('barc_config.item_name_bn') }}
                                                </template>
                                                <p class="text-dark"> {{ scoreSetupData.item_name_bn }} </p>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Publication" vid="publications">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="publications"
                                                >
                                                <template v-slot:label>
                                                {{ $t('barc_config.item_descrp_en') }}
                                                </template>
                                                <p class="text-dark"> {{ scoreSetupData.description }} </p>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Foundation Training" vid="foundation_training">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="foundation_training"
                                            >
                                            <template v-slot:label>
                                                {{$t('barc_config.item_descrp_bn')}}
                                            </template>
                                            <p class="text-dark"> {{ scoreSetupData.description_bn }} </p>
                                            </b-form-group>
                                        </ValidationProvider>
                                         <ValidationProvider name="Selection Committee" vid="selection_committee">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="selection_committee"
                                            >
                                            <template v-slot:label>
                                                {{$t('barc_config.item_setup_date')}}
                                                </template>
                                                <p class="text-dark"> {{ scoreSetupData.set_up_date|dateFormat }} </p>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.scoreSetupData = this.item
  },
  data () {
    return {
       scoreSetupData: {
        item_id: '',
        item_name: '',
        item_name_bn: '',
        set_up_date: '',
        description: '',
        description_bn: ''
      },
      drillingLog: '',
      slOffset: 1
    }
  },
  computed: {
     totScore: function () {
        return parseInt(this.scoreSetupData.academic_records) + parseInt(this.scoreSetupData.service_length) + parseInt(this.scoreSetupData.age) + parseInt(this.scoreSetupData.publications) + parseInt(this.scoreSetupData.foundation_training) + parseInt(this.scoreSetupData.acr) + parseInt(this.scoreSetupData.selection_committee)
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
  .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
