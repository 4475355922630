// enlisted University
export const enlistedUniversityBase = '/barc/config/barc-mas-university'
export const enlistedUniversityList = enlistedUniversityBase + '/list'
export const enlistedUniversityStatus = enlistedUniversityBase + '/toggle-status'
export const enlistedUniversityStore = enlistedUniversityBase + '/store'
export const enlistedUniversityUpdate = enlistedUniversityBase + '/update'
export const enlistedUniversityDestroy = enlistedUniversityBase + '/destroy'
// Approval Committee Setup
export const appCommitteeList = '/barc/config/app-committes/list'
export const appCommitteeStatus = '/barc/config/app-committes/toggle-status'
export const appCommitteeStore = '/barc/config/app-committes/store'
export const appCommitteeUpdate = '/barc/config/app-committes/update'
export const appCommitteeDestroy = '/barc/config/app-committes/destroy'

// required score setup
export const scoreSetupList = '/barc/config/score-setup/list'
export const scoreSetupStatus = '/barc/config/score-setup/toggle-status'
export const scoreSetupStore = '/barc/config/score-setup/store'
export const scoreSetupUpdate = '/barc/config/score-setup/update'
export const scoreSetupDestroy = '/barc/config/score-setup/destroy'
export const scoreSetupShow = '/barc/config/score-setup/show'
//  Self-Score Assessment Education
const educationAssessment = '/barc/config/self-score-assessment-education/'
export const ssAssessEduList = educationAssessment + 'list'
export const ssAssessEduStatus = educationAssessment + 'toggle-status'
export const ssAssessEduStore = educationAssessment + 'store'
export const ssAssessEduUpdate = educationAssessment + 'update'
export const ssAssessEduDestroy = educationAssessment + 'destroy'
export const ssAssessEduShow = educationAssessment + 'show'
//  Self-Score Assessment Others
export const selfScoreAssessmentOtherList = '/barc/config/self-score-assessment-others/list'
export const selfScoreAssessmentOtherStatus = '/barc/config/self-score-assessment-others/toggle-status'
export const selfScoreAssessmentOtherStore = '/barc/config/self-score-assessment-others/store'
export const selfScoreAssessmentOtherUpdate = '/barc/config/self-score-assessment-others/update'
export const selfScoreAssessmentOtherDestroy = '/barc/config/self-score-assessment-others/destroy'
export const selfScoreAssessmentOtherShow = '/barc/config/self-score-assessment-others/show'
//  Budget Policy Setup
export const budgetPolicySetupList = '/barc/config/budget-policies/list'
export const budgetPolicySetupStatus = '/barc/config/budget-policies/toggle-status'
export const budgetPolicySetupStore = '/barc/config/budget-policies/store'
export const budgetPolicySetupUpdate = '/barc/config/budget-policies/update'
export const budgetPolicySetupDestroy = '/barc/config/budget-policies/destroy'
export const budgetPolicySetupShow = '/barc/config/budget-policies/show'
// f-Score Policy Setup
export const userSetupApproveList = '/barc/config/barc-mas-com-user/list'
export const userSetupApproveStatus = '/barc/config/barc-mas-com-user/toggle-status'
export const userSetupApproveStore = '/barc/config/barc-mas-com-user/store'
export const userSetupApproveUpdate = '/barc/config/barc-mas-com-user/update'
export const userSetupApproveDestroy = '/barc/config/barc-mas-com-user/destroy'
export const userSetupApproveShow = '/barc/config/barc-mas-com-user/show'

// Grant Setup Master
export const GrantSetupBase = '/barc/config/grants'
export const GrantSetupList = GrantSetupBase + '/list'
export const GrantSetupStatus = GrantSetupBase + '/toggle-status'
export const GrantSetupStore = GrantSetupBase + '/store'
export const GrantSetupUpdate = GrantSetupBase + '/update'
export const GrantSetupDestroy = GrantSetupBase + '/destroy'

// Publication Type
export const publicationTypeList = '/barc/config/barc-mas-publication-type/list'
export const publicationTypeStore = '/barc/config/barc-mas-publication-type/store'
export const publicationTypeUpdate = '/barc/config/barc-mas-publication-type/update'
export const publicationTypeStatus = '/barc/config/barc-mas-publication-type/toggle-status'
export const publicationTypeDestroy = '/barc/config/barc-mas-publication-type/destroy'

// item budget policy setup
export const itemBudgetSetUpList = '/barc/config/items/list'
export const itemBudgetSetUpStore = '/barc/config/items/store'
export const itemBudgetSetUpUpdate = '/barc/config/items/update'
export const itemBudgetSetUpStatus = '/barc/config/items/toggle-status'
export const itemBudgetSetUpDestroy = '/barc/config/items/destroy'

// application name setup
export const applicationNameSetUpList = '/barc/config/barc-ms-app-names/list'
export const applicationNameSetUpStore = '/barc/config/barc-ms-app-names/store'
export const applicationNameSetUpUpdate = '/barc/config/barc-ms-app-names/update'
export const applicationNameSetUpStatus = '/barc/config/barc-ms-app-names/toggle-status'
export const applicationNameSetUpDestroy = '/barc/config/barc-ms-app-names/destroy'
// barc-ms-app-criteria name setup
export const appCriteriaSetupUpList = '/barc/config/barc-ms-app-criteria/list'
export const appCriteriaSetupStore = '/barc/config/barc-ms-app-criteria/store'
export const appCriteriaSetupUpdate = '/barc/config/barc-ms-app-criteria/update'
export const appCriteriaSetupStatus = '/barc/config/barc-ms-app-criteria/toggle-status'
export const appCriteriaSetupDestroy = '/barc/config/barc-ms-app-criteria/destroy'
// Circular Info setup
export const circularInfoList = '/barc/config/circular-information/list'
export const circularInfoStore = '/barc/config/circular-information/store'
export const circularInfoUpdate = '/barc/config/circular-information/update'
export const circularInfoStatus = '/barc/config/circular-information/toggle-status'
export const circularInfoDestroy = '/barc/config/circular-information/destroy'

// NARS Institute info setup
export const narsInstituteList = '/barc/config/nars-info/list'
export const narsInstituteStore = '/barc/config/nars-info/store'
export const narsInstituteUpdate = '/barc/config/nars-info/update'
export const narsInstituteStatus = '/barc/config/nars-info/toggle-status'
export const narsInstituteDestroy = '/barc/config/nars-info/destroy'

// NARS Institute info setup
export const fundSubHeadList = '/barc/config/fund-sub-head/list'
export const fundSubHeadShow = '/barc/config/fund-sub-head/show'
export const fundSubHeadStore = '/barc/config/fund-sub-head/store'
export const fundSubHeadUpdate = '/barc/config/fund-sub-head/update'
export const fundSubHeadStatus = '/barc/config/fund-sub-head/toggle-status'
export const fundSubHeadDestroy = '/barc/config/fund-sub-head/destroy'
